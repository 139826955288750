<template>
  <v-navigation-drawer v-model="toggled" app dark right class="text-right">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title"> Piximind </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list v-if="$store.getters.isAgent" dense nav>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        :to="item.to"
        link
        @click="$emit('toggled', false)"
      >
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item @click="logout">
        <v-list-item-content>
          <v-list-item-title>Déconnexion</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
    <v-list v-if="$store.getters.isAdmin" dense nav>
      <v-list-item
        v-for="item in adminItems"
        :key="item.title"
        :to="item.to"
        link
        @click="$emit('toggled', false)"
      >
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item link @click="logout">
        <v-list-item-content>
          <v-list-item-title>Déconnexion</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
export default {
  props: {
    toggled: {
      String,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      drawer: false,
      items: [
        {
          title: "TimeSheet",
          icon: "mdi-calendar",
          to: "/dashboard/timesheet",
        },
        {
          title: "Mon profile",
          icon: "mdi-account-box",
          to: "/dashboard/profile/photo",
        },
        {
          title: "Rapport projet",
          icon: "mdi-file-chart",
          to: "/dashboard/rapport",
        },
        {
          title: "Mes projets",
          icon: "mdi-view-dashboard",
          to: "/dashboard/projects",
        },
        {
          title: "Mes congés",
          icon: "mdi-calendar",
          to: "/dashboard/conge",
        },
      ],
      adminItems: [
        {
          title: "Tableau de bord",
          icon: "mdi-monitor-dashboard",
          to: "/dashboard/statistics",
        },
        {
          title: "Mon profile",
          icon: "mdi-account-box",
          to: "/dashboard/profile/photo",
        },
        {
          title: "Equipes",
          icon: "mdi-account-group",
          to: "/dashboard/ressources",
        },
        {
          title: "Dossier",
          icon: "mdi-view-dashboard",
          to: "/dashboard/dossiers",
        },
        {
          title: "Configuration des dossiers",
          icon: "mdi-cog",
          to: "/dashboard/dossier/config",
        },
        {
          title: "Time sheet",
          icon: "mdi-calendar",
          to: "/dashboard/timesheet/list",
        },
        {
          title: "Timesheet Mensuel",
          icon: "mdi-calendar",
          to: "/dashboard/timesheetMensuel/list",
        },
        {
          title: "Rapports",
          icon: "mdi-file-chart",
          to: "/dashboard/rapport/list",
        },
        {
          title: "Roles",
          icon: "mdi-account-box",
          to: "/dashboard/roles",
        },
        {
          title: "Suivi des congés",
          icon: "mdi-calendar",
          to: "/dashboard/conge/follow",
        },
      ],
      right: null,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>
